import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "count d-i ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createBlock("span", {
    class: ["df-cross-center", {'c-primary': _ctx.likeState}],
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleLike && _ctx.toggleLike(...args)), ["prevent"]))
  }, [
    _createVNode(_component_Icon, {
      name: "thumb-up",
      sm: _ctx.sm
    }, null, 8, ["sm"]),
    _renderSlot(_ctx.$slots, "default", { count: _ctx.countNo }, () => [
      _createVNode("span", _hoisted_1, _toDisplayString(_ctx.countNo), 1)
    ])
  ], 2))
}