
import * as R from 'ramda'
import { defineComponent } from 'vue'
import { renderProgram } from '@/modules/community/communities/community'
import TheImgGallery from './TheImgGallery.vue'
import TheLikeButton from './TheLikeButton.vue'
import Pic from 'common/Pic.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'TheComment',
  components: { Time, Pic, TheImgGallery, TheLikeButton },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  emits: ['reply'],
  setup (props) {
    return {
      hasQuote: R.complement(R.isNil)(props.comment.parentId),
      parentContent: renderProgram(props.comment.parentContent),
      content: renderProgram(props.comment.content),
    }
  },
})
