
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCommentLine',
  props: {
    num: {
      type: Number,
      default: 0,
    },
    isHot: {
      type: Boolean,
      default: false,
    },
  },
})
